import React, { useCallback } from 'react';
import { useRouter } from 'next/router';
import DoenLandingPage from '../DoenLandingPage';
import PageWithAuth from '../../../../components/PageWithAuth';
import { pathOr } from 'ramda';
import HomePage from '../../../marketing/containers/HomePage';
import pagesMeta from '../../../../constants/pages-meta';

const landingPagesMap = {
  doen: (
    <PageWithAuth showWaitList title={pagesMeta.doen.title} description={pagesMeta.doen.description}>
      <DoenLandingPage />
    </PageWithAuth>
  ),
};

const CustomLandingPage = () => {
  const router = useRouter();
  const { landingSlug } = router.query;

  const renderLandingPage = useCallback(() => {
    if (!landingPagesMap[landingSlug as string]) {
      router.push('https://www.thedopple.com/').then();
      return null;
    }
    return pathOr(<HomePage />, [landingSlug as string], landingPagesMap);
  }, [landingSlug]);

  return <>{landingSlug ? renderLandingPage() : <span>Loading...</span>}</>;
};

export default CustomLandingPage;
