import React, { FC, JSXElementConstructor, memo, ReactElement } from 'react';
import styled from 'styled-components';
import { Picture } from 'react-responsive-picture';
import Link from 'next/link';
import { Button } from '../../../../../components/Buttons';
import useQueryParams from '../../../../../hooks/useQueryParams';

const PhotoContainer = styled.div`
  display: flex;

  picture {
    display: block;
    margin-bottom: -4px;
  }

  img {
    width: 100%;
    height: auto;
    margin: 0;
  }

  @media screen and (min-width: 1200px) {
    width: 50%;

    &.first,
    &.second {
      picture {
        width: 100%;
      }
    }

    &.third {
      width: 100%;
    }
  }
`;

const MessageContainer = styled.div`
  background-color: #fff8f8;
  padding: 30px 26px;
  display: none;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  .wrapper {
    margin: 0;
    padding: 0;

    @media screen and (min-width: 414px) {
      max-width: 270px;
    }

    button {
      margin: 20px 0;
    }
  }

  .margin-top-large {
    margin-top: 35px !important;
  }

  p {
    font-weight: 400;
    line-height: 2;
  }

  @media screen and (min-width: 1200px) {
    display: flex;
    width: 33.3%;
  }
`;

const SubContainer = styled.div`
  ${MessageContainer} {
    display: flex;
  }

  @media screen and (min-width: 1200px) {
    width: 66.6%;
    display: flex;
    flex-flow: row wrap;

    ${MessageContainer} {
      display: none;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;

  @media screen and (min-width: 1200px) {
    flex-flow: row nowrap;
  }
`;

const StyledButton = styled(Button)`
  border-style: unset;
  align-items: center;
  height: 45px;
  font-weight: normal;
  width: 100%;
`;

type Props = {
  firstPhoto320: string;
  firstPhoto768: string;
  firstPhotoFull: string;
  secondPhoto320: string;
  secondPhoto768: string;
  secondPhotoFull: string;
  thirdPhoto320: string;
  thirdPhoto768: string;
  thirdPhotoFull: string;
  children: ReactElement | JSXElementConstructor<any>;
};

const MessageContent = ({ children }) => {
  const { query } = useQueryParams();

  return (
    <div className="wrapper">
      {children}
      <Link href={{ pathname: '/quiz', query }}>
        <StyledButton name="quiz" className="margin-top-large">
          Take the Quiz
        </StyledButton>
      </Link>
      <Link href={{ pathname: '/request-a-drop', query }}>
        <StyledButton name="single-drop">Request a Single Drop</StyledButton>
      </Link>
    </div>
  );
};

const ThreeBigImagesLayout: FC<Props> = ({
  children,
  firstPhoto320,
  firstPhoto768,
  firstPhotoFull,
  secondPhoto320,
  secondPhoto768,
  secondPhotoFull,
  thirdPhoto320,
  thirdPhoto768,
  thirdPhotoFull,
}) => (
  <Container>
    <SubContainer>
      <PhotoContainer className="first">
        <Picture
          sources={[
            {
              media: '(min-width: 1200px)',
              srcSet: firstPhotoFull,
            },
            {
              media: '(min-width: 600px)',
              srcSet: firstPhoto768,
            },
            {
              srcSet: firstPhoto320,
            },
          ]}
        />
      </PhotoContainer>
      <MessageContainer>
        <MessageContent>{children}</MessageContent>
      </MessageContainer>
      <PhotoContainer className="second">
        <Picture
          sources={[
            {
              media: '(min-width: 1200px)',
              srcSet: secondPhotoFull,
            },
            {
              media: '(min-width: 600px)',
              srcSet: secondPhoto768,
            },
            {
              srcSet: secondPhoto320,
            },
          ]}
        />
      </PhotoContainer>
      <PhotoContainer className="third">
        <Picture
          sources={[
            {
              media: '(min-width: 1200px)',
              srcSet: thirdPhotoFull,
            },
            {
              media: '(min-width: 600px)',
              srcSet: thirdPhoto768,
            },
            {
              srcSet: thirdPhoto320,
            },
          ]}
        />
      </PhotoContainer>
    </SubContainer>
    <MessageContainer>
      <MessageContent>{children}</MessageContent>
    </MessageContainer>
  </Container>
);

export default memo(ThreeBigImagesLayout);
