import React from 'react';
import ThreeBigImagesLayout from '../../components/layouts/ThreeBigImagesLayout';

import doenLogo from '../../../../../public/static/images/marketing/doen-landing-page/doen_logo.png';
import firstPhoto320 from '../../../../../public/static/images/marketing/doen-landing-page/doen_mobile_image_1.jpg';
import firstPhoto768 from '../../../../../public/static/images/marketing/doen-landing-page/doen_tablet_image_1.jpg';
import firstPhotoFull from '../../../../../public/static/images/marketing/doen-landing-page/doen_full_image_1.jpg';
import secondPhoto320 from '../../../../../public/static/images/marketing/doen-landing-page/doen_mobile_image_2.jpg';
import secondPhoto768 from '../../../../../public/static/images/marketing/doen-landing-page/doen_tablet_image_2.jpg';
import secondPhotoFull from '../../../../../public/static/images/marketing/doen-landing-page/doen_full_image_2.jpg';
import thirdPhoto320 from '../../../../../public/static/images/marketing/doen-landing-page/doen_mobile_image_3.jpg';
import thirdPhoto768 from '../../../../../public/static/images/marketing/doen-landing-page/doen_tablet_image_3.jpg';
import thirdPhotoFull from '../../../../../public/static/images/marketing/doen-landing-page/doen_full_image_3.jpg';
import styled from 'styled-components';

const LogoContainer = styled.div`
  margin: 20px 0;
`;

const DoenLandingPage = () => (
  <ThreeBigImagesLayout
    firstPhoto320={firstPhoto320}
    firstPhoto768={firstPhoto768}
    firstPhotoFull={firstPhotoFull}
    secondPhoto320={secondPhoto320}
    secondPhoto768={secondPhoto768}
    secondPhotoFull={secondPhotoFull}
    thirdPhoto320={thirdPhoto320}
    thirdPhoto768={thirdPhoto768}
    thirdPhotoFull={thirdPhotoFull}
  >
    <>
      <p>Introducing</p>
      <LogoContainer>
        <img src={doenLogo} />
      </LogoContainer>
      <p>
        The dreamy California cult brand brings their mini unisex collection (sizes 2T-8Y) to Dopple for one week only.
      </p>
      <p>
        To get Dôen, <strong>order by midnight PST 6/13/2019 and use the code DOEN</strong> at checkout to waive the
        Service Fee and get dibs on the limited Dôen Dopple Drop before the experience closes.
      </p>
      <p>
        If you're new to Dopple, simply take the quiz. If you're already a Dopple customer, simply order a Single Drop.
      </p>
    </>
  </ThreeBigImagesLayout>
);

export default DoenLandingPage;
